<template>
  <div
    class="
      d-flex
      flex-column
      align-items-center
      justify-content-between
      text-center
      p-3
    "
  >
    <h5 v-if="surveyTitle">{{ surveyTitle }}</h5>
    <vue-qr
      :logoSrc="logoSrc"
      :text="isSrc"
      :size="size"
      :callback="qrCallback"
      :qid="`${surveyId}`"
    ></vue-qr>
    <div class="d-flex flex-row justify-content-space">
      <input
        v-show="false"
        type="text"
        v-model="isSrc"
        id="inputTextQrCode"
        disabled
        class="mr-2"
      />

      <b-button size="sm" variant="primary" class="mr-2" @click="copyLink"
        >คัดลอก</b-button
      >

      <b-button v-if="srcImgBase64" size="sm" variant="primary"
        ><a
          :href="srcImgBase64"
          :download="`${surveyTitle}.png`"
          style="color: white"
        >
          ดาวน์โหลด</a
        ></b-button
      >
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
export default {
  components: {
    VueQr,
  },

  props: {
    surveyTitle: String,
    teachingDareId: Number,
    surveyId: Number,
  },

  data() {
    return {
      logoSrc:
        "https://dare-user-upload.s3.ap-southeast-1.amazonaws.com/background-certificate/logo.png",
      size: 200,
      srcImgBase64: "",
    };
  },

  computed: {
    isSrc() {
      return (
        window.location.protocol +
        "//" +
        window.location.host +
        `/survey-question?teachingDareId=${this.teachingDareId}&surveyId=${this.surveyId}`
      );
    },
  },

  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.isSrc);
    },
    qrCallback(dataUrl) {
      this.srcImgBase64 = dataUrl;
    },
  },
};
</script>
