<template>
  <b-modal
    v-if="item"
    :id="`modal-qr-code__${item.id}`"
    v-model="$show"
    centered
    size="xl"
    :hide-header="true"
    no-close-on-backdrop
  >
    <div v-if="surveys && surveys.length > 0" class="row">
      <base-qr-code
        class="col"
        v-for="isSurvey in surveys"
        :key="`isSurvey_${isSurvey.id}`"
        :teachingDareId="item.id"
        :surveyTitle="isSurvey.survey_title"
        :surveyId="isSurvey.id"
      ></base-qr-code>
    </div>

    <template #modal-footer="{ cancel }">
      <b-button size="sm" variant="dark" @click="cancel"> ปิด </b-button>
    </template>
  </b-modal>
</template>

<script>
import BaseQrCode from "../qrcode/BaseQrCode.vue";
import { Survey } from "../../models";
export default {
  components: {
    BaseQrCode,
  },

  props: {
    show: Boolean,
    item: Object,
  },

  data() {
    return {
      surveys: [],
    };
  },

  watch: {
    item: {
      immediate: true,
      handler: "getSurvey",
    },
  },

  computed: {
    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },
  },

  methods: {
    async getSurvey() {
      let { response } = await Survey.api().findAll();
      if (response && response.data) {
        const { data } = response.data;
        this.surveys = data;
      }
    },
  },

  model: {
    prop: "show",
    event: "update",
  },
};
</script>
